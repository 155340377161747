(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.All = f()}})(function(){var define,module,exports;return (function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _core = _interopRequireDefault(require("./js/_core"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

if (typeof window !== 'undefined') {
  if (!window.DOMM) window.DOMM = _core["default"];
}

},{"./js/_core":2}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var document = window.document;
var emptyNode = document.createElement('div'),
    emptyStyle = emptyNode.style;

var vendor = function () {
  var vendors = ['t', 'webkitT', 'MozT', 'msT', 'OT'],
      transform,
      i = 0,
      l = vendors.length;

  for (; i < l; i++) {
    transform = vendors[i] + 'ransform';
    if (transform in emptyStyle) return vendors[i].substr(0, vendors[i].length - 1);
  }

  return false;
}();
/**
 * キャメルケースへ変換 sampleString
 */


var camelCase = function camelCase(str) {
  str = str.charAt(0).toLowerCase() + str.slice(1);
  return str.replace(/[-_](.)/g, function (match, group1) {
    return group1.toUpperCase();
  });
};
/**
 * スネークケースへ変換 sample_string
 */


var snakeCase = function snakeCase(str) {
  var camel = camelCase(str);
  return camel.replace(/[A-Z]/g, function (s) {
    return "_" + s.charAt(0).toLowerCase();
  });
};
/**
 * ケバブケースへ変換 sample_string
 */


var kebabCase = function kebabCase(str) {
  var camel = camelCase(str);
  return camel.replace(/[A-Z]/g, function (s) {
    return "-" + s.charAt(0).toLowerCase();
  });
};
/**
 * パスカルケースへ変換 SampleString
 */


var pascalCase = function pascalCase(str) {
  var camel = camelCase(str);
  return camel.charAt(0).toUpperCase() + camel.slice(1);
};

var DOMM = function DOMM(selector) {
  return new DOMM.init(selector);
};

DOMM.prototype = DOMM.fn = {
  extend: function extend(obj, prop) {
    for (var i in prop) {
      if (prop.hasOwnProperty(i)) {
        obj[i] = prop[i];
      }
    }
  },
  isWindow: function isWindow(obj) {
    return obj != null && obj === obj.window;
  },
  type: function type(obj) {
    if (obj == null) {
      return obj + "";
    }

    return _typeof(obj);
  },
  isArray: function isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  },
  isObject: function isObject(obj) {
    return DOMM.fn.type(obj) === 'object' && obj !== null && !this.isArray(obj);
  },
  isFunction: function isFunction(obj) {
    return DOMM.fn.type(obj) === 'function';
  },
  inArray: function inArray(value, array) {
    return [].indexOf.call(array, value) < 0 ? false : true;
  },
  pregQuote: function pregQuote(str, delimiter) {
    return (str + '').replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g'), '\\$&');
  }
};
DOMM.fn.extend(DOMM.fn, {
  each: function each(el, callback) {
    var length,
        i = 0;

    if (el && !('length' in el)) {
      el = [el];
    }

    length = el.length;

    for (; i < length; i++) {
      if (callback.call(el[i], i, el[i]) === false) {
        break;
      }
    }

    return el;
  },
  remove: function remove(el) {
    if ('remove' in window) {
      el.remove();
    } else {
      el.parentNode.removeChild(el);
    }
  },
  wrap: function wrap(el, wrapEl) {
    el.parentNode.insertBefore(wrapEl, el);
    wrapEl.appendChild(el);
  },
  unwrap: function unwrap(el) {
    while (el.firstChild) {
      el.parentNode.insertBefore(el.firstChild, el);
    }

    DOMM.fn.remove(el);
  },
  closest: function closest(el, selector) {
    return (el.closest || function (_selector) {
      do {
        if ((el.matches || el.msMatchesSelector).call(el, _selector)) {
          return el;
        }

        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);

      return null;
    }).call(el, selector);
  },
  parent: function parent(el, selector) {
    if (selector) {
      return el.parentNode;
    } else {
      return DOMM.fn.closest(el, selector);
    }
  },
  eventPreventDefault: function eventPreventDefault(event) {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
  },
  passiveSupported: function passiveSupported() {
    var passiveSupported = false;
    window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
      get: function get() {
        passiveSupported = true;
      }
    }));
    return passiveSupported ? {
      passive: false
    } : false;
  },
  addEvent: function addEvent(el, type, callback, capture) {
    el.addEventListener(type, callback, capture || DOMM.fn.passiveSupported());
  },
  removeEvent: function removeEvent(el, type, callback, capture) {
    el.removeEventListener(type, callback, capture || false);
  },
  on: function on(el, type, callback, capture) {
    var i = 0,
        t = type.split(' '),
        l = t.length;

    for (; i < l; ++i) {
      if (t[i]) {
        DOMM.fn.addEvent(el, t[i], callback, capture);
      }
    }
  },
  off: function off(el, type, callback, capture) {
    var i = 0,
        t = type.split(' '),
        l = t.length;

    for (; i < l; ++i) {
      if (t[i]) {
        DOMM.fn.removeEvent(el, t[i], callback, capture);
      }
    }
  },
  trigger: function trigger(el, type) {
    var event;

    if (document.createEvent) {
      event = document.createEvent('HTMLEvents');
      event.initEvent(type, true, true);
      return el.dispatchEvent(event);
    } else {
      event = document.createEventObject();
      return el.fireEvent("on".concat(type), event);
    }
  },
  hasClass: function hasClass(el, className) {
    return el.className && new RegExp('(^|\\s)' + DOMM.fn.pregQuote(className) + '(\\s|$)').test(el.className);
  },
  addClass: function addClass(el, className) {
    if (DOMM.fn.hasClass(el, className)) {
      return;
    }

    el.className += (el.className ? ' ' : '') + className;
  },
  removeClass: function removeClass(el, className) {
    if (!DOMM.fn.hasClass(el, className)) {
      return;
    }

    var reg = new RegExp('(\\s|^)' + DOMM.fn.pregQuote(className) + '(\\s|$)');
    el.className = el.className.replace(reg, ' ').replace(/^\s\s*/, '').replace(/\s\s*$/, '');
  },
  toggleClass: function toggleClass(el, className) {
    if (DOMM.fn.hasClass(el, className)) {
      DOMM.fn.removeClass(el, className);
    } else {
      DOMM.fn.addClass(el, className);
    }
  },
  prefixStyle: function prefixStyle(style) {
    if (vendor === false) return false;
    if (vendor === '') return style;
    return vendor + style.charAt(0).toUpperCase() + style.substr(1);
  },
  getStyle: function getStyle(el, prop) {
    var style = el.currentStyle || document.defaultView.getComputedStyle(el, '');

    if (prop) {
      return String(style.getPropertyValue(DOMM.fn.prefixStyle(prop))).trim();
    } else {
      return style;
    }
  },
  setStyle: function setStyle(el, prop, value) {
    el.style[DOMM.fn.prefixStyle(prop)] = value || null;
  },
  getCustomProperty: function getCustomProperty(prop) {
    return DOMM.fn.getStyle(document.documentElement, prop);
  },
  setCustomProperty: function setCustomProperty(prop, defaultValue) {
    var value = DOMM.fn.getCustomProperty(prop);
    return value || defaultValue;
  },
  offset: function offset(el) {
    var rect = el.getBoundingClientRect();
    var scrollTop = window.pageYOffset || DOMM.fn.windowScrollTarget.scrollTop;
    var scrollLeft = window.pageXOffset || DOMM.fn.windowScrollTarget.scrollLeft;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft
    };
  },
  getScrollTop: function getScrollTop() {
    return typeof window.pageYOffset === 'number' ? window.pageYOffset : DOMM.fn.windowScrollTarget.scrollTop;
  },
  getScrollBottom: function getScrollBottom() {
    return DOMM.fn.windowScrollTarget.scrollHeight - DOMM.fn.windowScrollTarget.clientHeight;
  }
});
var ua = navigator.userAgent;
DOMM.fn.extend(DOMM.fn.ua = {}, {
  isIe: ua.match(/MSIE/i) || ua.match(/Trident/i),
  isEdge: ua.match(/Edge/i),
  isFireFox: ua.match(/Firefox/i) != null,
  isChrome: ua.match(/Chrome/i),
  isSafari: ua.match(/Safari/i) && !DOMM.fn.ua.isChrome && !DOMM.fn.ua.isEdge
});
DOMM.fn.extend(DOMM.fn, {
  hasTransform: DOMM.fn.prefixStyle('transform') !== false,
  hasPerspective: DOMM.fn.prefixStyle('perspective') in emptyStyle,
  hasTransition: DOMM.fn.prefixStyle('transition') in emptyStyle,
  hasTouch: 'ontouchstart' in window,
  hasPointer: !!(window.PointerEvent || window.MSPointerEvent),
  supportScrollTo: 'scrollTo' in window,
  windowScrollTarget: 'scrollingElement' in document ? document.scrollingElement : ua.toLowerCase().match(/webkit|msie 5/) ? document.body : document.documentElement,
  mouseWheelEvent: 'onwheel' in emptyNode ? 'wheel' : document.onmousewheel !== undefined ? 'mousewheel' : 'mouseScroll'
});
DOMM.fn.config = {
  duration: 500,
  duration_fast: 300,
  duration_slow: 700,
  animationFrameTime: 1000.0 / 60
};
DOMM.fn.easing = {
  linear: function linear(t, b, c, d) {
    return c * t / d + b;
  },
  easeInQuad: function easeInQuad(t, b, c, d) {
    return c * (t /= d) * t + b;
  },
  easeOutQuad: function easeOutQuad(t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  },
  easeInOutQuad: function easeInOutQuad(t, b, c, d) {
    if ((t /= d / 2) < 1) return c / 2 * t * t + b;
    return -c / 2 * (--t * (t - 2) - 1) + b;
  },
  "default": 'easeInOutQuad'
};
DOMM.fn.extend(DOMM.fn, {
  requestAnimationFrame: function requestAnimationFrame(callback, time) {
    var request = window[DOMM.fn.prefixStyle('requestAnimationFrame')] || window.setTimeout;
    return request(callback, time);
  },
  cancelAnimationFrame: function cancelAnimationFrame(callback) {
    var cancel = window[DOMM.fn.prefixStyle('cancelAnimationFrame')] || window.clearTimeout;
    return cancel(callback);
  },
  scrollTo: function (_scrollTo) {
    function scrollTo(_x, _x2, _x3, _x4, _x5) {
      return _scrollTo.apply(this, arguments);
    }

    scrollTo.toString = function () {
      return _scrollTo.toString();
    };

    return scrollTo;
  }(function (to, from, duration, easing, callback) {
    to = parseInt(to - from);
    easing = DOMM.fn.easing[easing];
    var animLoop,
        startTime = Date.now();

    (function loop() {
      var currentTime = Date.now() - startTime;

      if (currentTime < duration) {
        scrollTo(0, easing(currentTime, from, to, duration));
        animLoop = DOMM.fn.requestAnimationFrame(loop, DOMM.fn.config.animationFrameTime);
      } else {
        scrollTo(0, to + from);
        DOMM.fn.cancelAnimationFrame(animLoop);
        if (DOMM.fn.isFunction(callback)) callback();
      }
    })();
  }),
  scrollAnimation: function scrollAnimation(to, from, duration, easing, callback) {
    if (isNaN(parseInt(to))) to = 0;
    if (!from) from = DOMM.fn.getScrollTop();
    if (!duration) duration = DOMM.fn.config.duration;
    if (!easing) easing = DOMM.fn.easing.hasOwnProperty(easing) ? easing : DOMM.fn.easing["default"];
    DOMM.fn.scrollTo(to, from, duration, easing, callback);
  },
  scrollToAnimation: function scrollToAnimation(to, callback) {
    var onScroll = function onScroll() {
      if (to === DOMM.fn.getScrollTop()) {
        DOMM.fn.off(window, 'scroll', onScroll);
        if (DOMM.fn.isFunction(callback)) callback();
      }
    };

    DOMM.fn.on(window, 'scroll', onScroll);
    onScroll();
    window.scrollTo({
      top: to,
      behavior: 'smooth'
    });
  }
}); // メソッドを拡張

DOMM.extend = function (prop) {
  DOMM.fn.extend(DOMM.methods = {}, prop);
};

DOMM.extend({
  each: function each(callback) {
    DOMM.fn.each(DOMM.els, callback);
  },
  addClass: function addClass(value) {
    this.each(function (i, el) {
      DOMM.fn.addClass(el, value);
    });
    return this;
  },
  removeClass: function removeClass(value) {
    this.each(function (i, el) {
      DOMM.fn.removeClass(el, value);
    });
    return this;
  },
  toggleClass: function toggleClass(value) {
    this.each(function (i, el) {
      DOMM.fn.toggleClass(el, value);
    });
    return this;
  },
  hasClass: function hasClass(value) {
    var r = false;
    DOMM.fn.each(DOMM.els, function (i, el) {
      if (DOMM.fn.hasClass(el, value)) {
        r = true;
        return false;
      }
    });
    return r;
  },
  // イベント
  eventPreventDefault: function eventPreventDefault(event) {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
  },
  on: function on(type, callback, capture) {
    this.each(function (i, el) {
      DOMM.fn.on(el, type, callback, capture);
    });
  },
  off: function off(type, callback, capture) {
    this.each(function (i, el) {
      DOMM.fn.off(el, type, callback, capture);
    });
  },
  trigger: function trigger(type) {
    this.each(function (i, el) {
      DOMM.fn.trigger(el, type);
    });
  }
}); // 初期化

var init = DOMM.init = function (selector) {
  if (DOMM.fn.isObject(selector)) {
    DOMM.els = selector;
  } else {
    DOMM.els = document.querySelectorAll(selector) || this;
  }

  return this;
};

init.prototype = DOMM.methods;
var _default = DOMM;
exports["default"] = _default;

},{}],3:[function(require,module,exports){
"use strict";

/*! instant.page v5.1.0 - (C) 2019-2020 Alexandre Dieulot - https://instant.page/license */
var mouseoverTimer;
var lastTouchTimestamp;
var prefetches = new Set();
var prefetchElement = document.createElement('link');
var isSupported = prefetchElement.relList && prefetchElement.relList.supports && prefetchElement.relList.supports('prefetch') && window.IntersectionObserver && 'isIntersecting' in IntersectionObserverEntry.prototype;
var allowQueryString = ('instantAllowQueryString' in document.body.dataset);
var allowExternalLinks = ('instantAllowExternalLinks' in document.body.dataset);
var useWhitelist = ('instantWhitelist' in document.body.dataset);
var mousedownShortcut = ('instantMousedownShortcut' in document.body.dataset);
var DELAY_TO_NOT_BE_CONSIDERED_A_TOUCH_INITIATED_ACTION = 1111;
var delayOnHover = 65;
var useMousedown = false;
var useMousedownOnly = false;
var useViewport = false;

if ('instantIntensity' in document.body.dataset) {
  var intensity = document.body.dataset.instantIntensity;

  if (intensity.substr(0, 'mousedown'.length) == 'mousedown') {
    useMousedown = true;

    if (intensity == 'mousedown-only') {
      useMousedownOnly = true;
    }
  } else if (intensity.substr(0, 'viewport'.length) == 'viewport') {
    if (!(navigator.connection && (navigator.connection.saveData || navigator.connection.effectiveType && navigator.connection.effectiveType.includes('2g')))) {
      if (intensity == "viewport") {
        /* Biggest iPhone resolution (which we want): 414 × 896 = 370944
         * Small 7" tablet resolution (which we don’t want): 600 × 1024 = 614400
         * Note that the viewport (which we check here) is smaller than the resolution due to the UI’s chrome */
        if (document.documentElement.clientWidth * document.documentElement.clientHeight < 450000) {
          useViewport = true;
        }
      } else if (intensity == "viewport-all") {
        useViewport = true;
      }
    }
  } else {
    var milliseconds = parseInt(intensity);

    if (!isNaN(milliseconds)) {
      delayOnHover = milliseconds;
    }
  }
}

if (isSupported) {
  var eventListenersOptions = {
    capture: true,
    passive: true
  };

  if (!useMousedownOnly) {
    document.addEventListener('touchstart', touchstartListener, eventListenersOptions);
  }

  if (!useMousedown) {
    document.addEventListener('mouseover', mouseoverListener, eventListenersOptions);
  } else if (!mousedownShortcut) {
    document.addEventListener('mousedown', mousedownListener, eventListenersOptions);
  }

  if (mousedownShortcut) {
    document.addEventListener('mousedown', mousedownShortcutListener, eventListenersOptions);
  }

  if (useViewport) {
    var triggeringFunction;

    if (window.requestIdleCallback) {
      triggeringFunction = function triggeringFunction(callback) {
        requestIdleCallback(callback, {
          timeout: 1500
        });
      };
    } else {
      triggeringFunction = function triggeringFunction(callback) {
        callback();
      };
    }

    triggeringFunction(function () {
      var intersectionObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            var linkElement = entry.target;
            intersectionObserver.unobserve(linkElement);
            preload(linkElement.href);
          }
        });
      });
      document.querySelectorAll('a').forEach(function (linkElement) {
        if (isPreloadable(linkElement)) {
          intersectionObserver.observe(linkElement);
        }
      });
    });
  }
}

function touchstartListener(event) {
  /* Chrome on Android calls mouseover before touchcancel so `lastTouchTimestamp`
   * must be assigned on touchstart to be measured on mouseover. */
  lastTouchTimestamp = performance.now();
  var linkElement = event.target.closest('a');

  if (!isPreloadable(linkElement)) {
    return;
  }

  preload(linkElement.href);
}

function mouseoverListener(event) {
  if (performance.now() - lastTouchTimestamp < DELAY_TO_NOT_BE_CONSIDERED_A_TOUCH_INITIATED_ACTION) {
    return;
  }

  var linkElement = event.target.closest('a');

  if (!isPreloadable(linkElement)) {
    return;
  }

  linkElement.addEventListener('mouseout', mouseoutListener, {
    passive: true
  });
  mouseoverTimer = setTimeout(function () {
    preload(linkElement.href);
    mouseoverTimer = undefined;
  }, delayOnHover);
}

function mousedownListener(event) {
  var linkElement = event.target.closest('a');

  if (!isPreloadable(linkElement)) {
    return;
  }

  preload(linkElement.href);
}

function mouseoutListener(event) {
  if (event.relatedTarget && event.target.closest('a') == event.relatedTarget.closest('a')) {
    return;
  }

  if (mouseoverTimer) {
    clearTimeout(mouseoverTimer);
    mouseoverTimer = undefined;
  }
}

function mousedownShortcutListener(event) {
  if (performance.now() - lastTouchTimestamp < DELAY_TO_NOT_BE_CONSIDERED_A_TOUCH_INITIATED_ACTION) {
    return;
  }

  var linkElement = event.target.closest('a');

  if (event.which > 1 || event.metaKey || event.ctrlKey) {
    return;
  }

  if (!linkElement) {
    return;
  }

  linkElement.addEventListener('click', function (event) {
    if (event.detail == 1337) {
      return;
    }

    event.preventDefault();
  }, {
    capture: true,
    passive: false,
    once: true
  });
  var customEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
    detail: 1337
  });
  linkElement.dispatchEvent(customEvent);
}

function isPreloadable(linkElement) {
  if (!linkElement || !linkElement.href) {
    return;
  }

  if (useWhitelist && !('instant' in linkElement.dataset)) {
    return;
  }

  if (!allowExternalLinks && linkElement.origin != location.origin && !('instant' in linkElement.dataset)) {
    return;
  }

  if (!['http:', 'https:'].includes(linkElement.protocol)) {
    return;
  }

  if (linkElement.protocol == 'http:' && location.protocol == 'https:') {
    return;
  }

  if (!allowQueryString && linkElement.search && !('instant' in linkElement.dataset)) {
    return;
  }

  if (linkElement.hash && linkElement.pathname + linkElement.search == location.pathname + location.search) {
    return;
  }

  if ('noInstant' in linkElement.dataset) {
    return;
  }

  return true;
}

function preload(url) {
  if (prefetches.has(url)) {
    return;
  }

  var prefetcher = document.createElement('link');
  prefetcher.rel = 'prefetch';
  prefetcher.href = url;
  document.head.appendChild(prefetcher);
  prefetches.add(url);
}

},{}],4:[function(require,module,exports){
"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function (window, factory) {
  var PageScroll = factory(window, window.document, DOMM);
  window.PageScroll = PageScroll;

  if ((typeof module === "undefined" ? "undefined" : _typeof(module)) == 'object' && module.exports) {
    module.exports = PageScroll;
  }
})(typeof window != 'undefined' ? window : {}, function l(window, document, DOMM) {
  var _this = this;

  var document = window.document,
      $ = DOMM.fn;
  var PageScroll = {},
      options = {};

  (function () {
    var prop,
        PageScrollDefaults = {
      init: true,
      // インストールの実行
      browserHash: true,
      // ブラウザハッシュの利用
      scrollAnimation: true,
      // アニメーションの利用
      useScrollTo: false,
      // 'window.scrollTo'の利用
      duration: parseInt($.setCustomProperty('--transition-speed', $.config.duration)),
      // スクロールスピード
      easing: $.easing["default"],
      negativeElementClassName: 'negative-PageScroll',
      // 指定した要素の高さ分スクロール到達位置をずらす
      noPagescrollClassName: 'no-pagescroll',
      // 除外する要素
      pagescrollTopClassName: 'pagescroll-top' // ページ上部へ移動する要素

    };
    options = window.PageScrollOptions || {};

    for (prop in PageScrollDefaults) {
      if (!(prop in options)) {
        options[prop] = PageScrollDefaults[prop];
      }
    }
  })();

  if (!document || !document.getElementsByClassName) {
    PageScroll = {
      options: options,
      init: function init() {},
      noSupport: true
    };
  }

  PageScroll.scrollTimer;
  PageScroll.eventAllowFlag = false;
  /**
   * 'scrollBehavior'のサポート
   */

  var supportScrollBehavior = function supportScrollBehavior() {
    if ('scrollBehavior' in document.documentElement.style) {
      return true;
    }

    return false;
  };
  /**
   * URLからハッシュの削除
   */


  var removeBrowserHash = function removeBrowserHash() {
    var uri = window.location.toString();

    if (uri.indexOf("#") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("#"));
      window.history.replaceState({}, document.title, clean_uri);
    }
  };
  /**
   * アニメーション付きのスクロール
   * 
   * @param {integer} to 
   * @param {integer} from 
   * @param {integer} duration 
   * @param {string} easing 
   * @param {function} callback 
   */


  var scrollToAnimation = function scrollToAnimation(to, from, duration, easing, callback) {
    if (options.useScrollTo && $.supportScrollTo()) {
      $.scrollToAnimation(to, callback);
    } else {
      $.scrollAnimation(to, from, duration, easing, callback);
    }
  };
  /**
   * アニメーションなしのスクロール
   * 
   * @param {integer} to 
   * @param {function} callback 
   */


  var scrollToSilent = function scrollToSilent(to, callback) {
    // 移動
    $.windowScrollTarget.scrollTop = to; // コールバック「

    if ($.isFunction(callback)) callback();
  };
  /**
   * スクロールを移動
   * 
   * @param {integer} to
   * @param {integer} from
   * @param {integer} duration
   * @param {string} easing
   * @param {function} callback
   */


  var scrollTo = function scrollTo(to, from, duration, easing, callback) {
    if (options.scrollAnimation) {
      scrollToAnimation(to, from, duration, easing, callback);
    } else {
      scrollToSilent(to, callback);
    }
  };
  /**
   * 移動後のコールバック
   * 
   * @param {*} scrollToTarget
   */


  var scrollCallback = function scrollCallback(target) {
    // フォーカスを移動できれば移動
    if (typeof target !== 'undefined' && /(TEXTAREA|INPUT)/i.test(target.nodeName)) {
      target.focus();

      if (document.activeElement !== target) {
        target.tabIndex = -1;
        target.focus();
      }
    }
  }; // 除外要素の場合


  var isNotTarget = function isNotTarget(e) {
    if ($.hasClass(e.target, options.noPagescrollClassName)) {
      return true;
    }

    return false;
  };
  /**
   * ページ上部へ移動する要素の判定
   * 
   * @param {*} e
   */


  var isScrollTopTarget = function isScrollTopTarget(target) {
    return $.hasClass(target, options.pagescrollTopClassName) || $.closest(target, '.' + options.pagescrollTopClassName);
  };
  /**
   * キーボードの特殊キー
   * 
   * @param {object} e 
   */


  var isKeyboadEvents = function isKeyboadEvents(e) {
    return e.ctrlKey || e.shiftKey || e.altKey || e.metaKey;
  };
  /**
   * アンカー要素を取得
   * 
   * @param {object} target
   */


  var getAnchorTarget = function getAnchorTarget(target) {
    if ('A' !== target.nodeName) {
      target = $.closest(target, 'a');
    }

    if (target !== null) {
      return target;
    }

    return false;
  };
  /**
   * スクロール先を検索
   * 
   * @param {object} target
   */


  var getScrollToTarget = function getScrollToTarget(target) {
    var hash = target.hash,
        id,
        params,
        queryString,
        queryPos,
        toNode;

    if (hash === undefined) {
      return false;
    }

    id = hash;
    queryPos = id.indexOf('?');

    if (queryPos > -1) {
      id = id.substring(0, queryPos);
      params = id.substring(queryPos + 1);
    }

    id = id.substring(1);
    toNode = document.getElementById(id);

    if (toNode === null) {
      return false;
    } // ブラウザハッシュへ登録


    if (options.browserHash) {
      queryString = document.location.search;

      if (params) {
        if (queryString) {
          queryString = queryString + "&".concat(params);
        } else {
          queryString = "?".concat(params);
        }
      }

      history.pushState({}, '', hash + queryString);
    }

    return toNode;
  };

  var eventPageScroll = function eventPageScroll(e) {
    var target = e.target || e.srcElement;

    if (!PageScroll.eventAllowFlag) {
      PageScroll.eventAllowFlag = true;
    } else {
      return false;
    } // キーボード操作との組み合わせの場合は停止


    if (isKeyboadEvents(e) || isNotTarget(e)) {
      PageScroll.eventAllowFlag = false;
      return false;
    } // スクロールトップを優先


    if (isScrollTopTarget(target)) {
      $.eventPreventDefault(e);
      scrollTo(0, $.getScrollTop(), options.duration, options.easing, function () {
        scrollCallback();
        PageScroll.eventAllowFlag = false;
      });
      return false;
    }

    target = getAnchorTarget(target);

    if (!target) {
      PageScroll.eventAllowFlag = false;
      return false;
    } // ページ内スクロール以外のリンク形式


    var pos = target.href.indexOf("#");

    if (pos < 1 || pos >= 0 && target.href.substring(0, pos) !== location.href.substring(0, pos)) {
      PageScroll.eventAllowFlag = false;
      return false;
    } // スクロール先が不明な場合


    var scrollToTarget = getScrollToTarget(target);

    if (!scrollToTarget) {
      PageScroll.eventAllowFlag = false;
      return false;
    } // イベント停止


    $.eventPreventDefault(e);
    var targetOffsetTop = $.offset(scrollToTarget).top,
        negativeValue = 0; // 固定要素の場合

    if ($.inArray($.getStyle(scrollToTarget, 'position'), ['sticky', 'fixed'])) {
      targetOffsetTop = parseInt($.getStyle(scrollToTarget, 'top'));
    } // スクロール位置を取得


    var negativeHeightTarget = document.getElementsByClassName(options.negativeElementClassName);

    if (negativeHeightTarget.length) {
      if (scrollToTarget !== negativeHeightTarget) {
        negativeValue = negativeHeightTarget[0].clientHeight;
      }
    }

    var to = targetOffsetTop - negativeValue;
    to = to > $.getScrollBottom() ? $.getScrollBottom() : to; // アニメーションの実行

    scrollTo(to, $.getScrollTop(), options.duration, options.easing, function (scrollToTarget) {
      scrollCallback(scrollToTarget);
      PageScroll.eventAllowFlag = false;
    }.bind(_this, scrollToTarget));
  };

  var eventPageScrollReset = function eventPageScrollReset(e) {
    clearTimeout(PageScroll.scrollTimer);
    PageScroll.scrollTimer = setTimeout(function () {
      PageScroll.eventAllowFlag = false;
    }, 500);
  };

  var init = function init() {
    $.on(document, 'click', eventPageScroll);
    $.on(window, 'scroll', eventPageScrollReset);
  };

  var destroy = function destroy() {
    $.off(document, 'click', eventPageScroll);
    $.off(window, 'scroll', eventPageScrollReset);
  };

  setTimeout(function () {
    if (options.init) {
      init();
    }
  });
  $.extend(PageScroll, {
    options: options,
    init: init,
    destroy: destroy
  });
  return PageScroll;
});

},{}],5:[function(require,module,exports){
"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function (window, factory) {
  var Ripple = factory(window, window.document, DOMM);
  window.Ripple = Ripple;

  if ((typeof module === "undefined" ? "undefined" : _typeof(module)) == 'object' && module.exports) {
    module.exports = Ripple;
  }
})(typeof window != 'undefined' ? window : {}, function l(window, document, DOMM) {
  var document = window.document,
      $ = DOMM.fn;
  var Ripple = {},
      options = {};

  (function () {
    var prop,
        rippleDefaults = {
      init: true,
      // インストールの実行
      rippleEffectClassName: 'ripple-effect',
      rippleClassName: 'ripple',
      rippleMaxSize: 280
    };
    options = window.rippleOptions || {};

    for (prop in rippleDefaults) {
      if (!(prop in options)) {
        options[prop] = rippleDefaults[prop];
      }
    }
  })();

  if (!document || !document.getElementsByClassName) {
    return Ripple = {
      options: options,
      init: function init() {},
      noSupport: true
    };
  }

  var eventRippleEffect = function eventRippleEffect(e) {
    var target = e.target || e.srcElement,
        cover,
        size,
        loc,
        x,
        y;

    if (!$.hasClass(target, options.rippleClassName)) {
      target = $.closest(target, '.' + options.rippleClassName);
    }

    cover = document.createElement('span');
    size = target.offsetWidth;
    size = size > options.rippleMaxSize ? options.rippleMaxSize : size;
    loc = $.offset(target);
    x = e.pageX - loc.left - size / 2;
    y = e.pageY - loc.top - size / 2;
    cover.setAttribute('style', "top: ".concat(y, "px; left: ").concat(x, "px; height: ").concat(size, "px; width: ").concat(size, "px;"));
    $.addClass(cover, options.rippleEffectClassName);
    target.appendChild(cover);
    $.on(cover, 'animationend', function () {
      cover.parentNode.removeChild(cover);
    });
  };

  var init = function init() {
    Ripple.els = document.getElementsByClassName(options.rippleClassName);
    if (!Ripple.els.length) return false;
    $.each(Ripple.els, function (i, el) {
      $.on(el, 'click', eventRippleEffect);
    });
  };

  var destroy = function destroy() {
    if (!Ripple.els) return false;
    $.each(Ripple.els, function (i, el) {
      $.off(el, 'click', eventRippleEffect);
    });
  };

  setTimeout(function () {
    if (options.init) {
      init();
    }
  });
  $.extend(Ripple, {
    options: options,
    init: init,
    destroy: destroy
  });
  return Ripple;
});

},{}],6:[function(require,module,exports){
"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function (window, factory) {
  var SlideMenu = factory(window, window.document, DOMM);
  window.SlideMenu = SlideMenu;

  if ((typeof module === "undefined" ? "undefined" : _typeof(module)) == 'object' && module.exports) {
    module.exports = SlideMenu;
  }
})(typeof window != 'undefined' ? window : {}, function l(window, document, DOMM) {
  var document = window.document,
      $ = DOMM.fn;
  var SlideMenu = {},
      options = {}; // Object.valuesのpollyfill

  if (!Object.values) {
    Object.values = function (obj) {
      return Object.keys(obj).map(function (key) {
        return obj[key];
      });
    };
  } // bool型変換


  var toBoolean = function toBoolean(str) {
    return str.toString().toLowerCase() === 'true';
  };

  (function () {
    var prop,
        SlideMenuDefaults = {
      init: true,
      slidemenuLayout: $.setCustomProperty('--slidemenu-layout', 'left'),
      // デフォルトのレイアウト
      useCss: toBoolean($.setCustomProperty('--slidemenu-use-css', true)),
      // cssのみでアニメーションを行う場合
      // クラス名
      slidemenuContainerClass: 'slidemenu-container',
      slidemenuTriggerClass: 'slidemenu-trigger',
      slidemenuWrapperClass: 'slidemenu-wrapper',
      slidemenuContentClass: 'slidemenu-content',
      slidemenuOverlayClass: 'slidemenu-overlay',
      slidemenuNoscrollClass: 'noscroll-slidemenu',
      slidemenuOpenClass: 'open-slidemenu',
      slidemenuOpenProgressClass: 'open-progress-slidemenu',
      // レスポンシブ
      responsive: {
        mobile: {
          slidemenuLayout: 'left',
          breakpoint: parseInt($.setCustomProperty('--mb-breakpoint', 480))
        },
        tablet: {
          slidemenuLayout: 'left',
          breakpoint: parseInt($.setCustomProperty('--tb-breakpoint', 768))
        },
        desktop: {
          slidemenuLayout: 'center',
          breakpoint: parseInt($.setCustomProperty('--dt-breakpoint', 960))
        }
      },
      windowScrollTarget: $.windowScrollTarget,
      animationFrameTime: $.config.animationFrameTime || parseInt(1000.0 / 60)
    };
    options = window.SlideMenuOptions || {};

    for (prop in SlideMenuDefaults) {
      if (!(prop in options)) {
        options[prop] = SlideMenuDefaults[prop];
      }
    }
  })();

  if (!document || !document.getElementsByClassName) {
    return SlideMenu = {
      options: options,
      init: function init() {},
      noSupport: true
    };
  }

  var $html = document.getElementsByTagName('html')[0];
  var $body = document.body;

  var initialGlobalOptions = function initialGlobalOptions() {
    SlideMenu.els = [];
    SlideMenu.extensions = {};
    SlideMenu.activeIndex = null;
    SlideMenu.scrollTop = 0;
    SlideMenu.open = false;
    SlideMenu.eventFlag = false;
    SlideMenu.bodyMarginTop = parseInt($.getStyle($body, 'margin-top')) || 0;
    SlideMenu.$elOverlay = null;
    SlideMenu.layoutTypes = ['center', 'left', 'right'];
  };

  initialGlobalOptions(); // 数値の判定

  var isNum = function isNum(value) {
    var pattern = /^\d*$/;
    return pattern.test(value);
  };

  var camelCase = function camelCase(str) {
    str = str.charAt(0).toString().toLowerCase() + str.slice(1);
    return str.replace(/[-_](.)/g, function (match, group1) {
      return group1.toUpperCase();
    });
  };

  var kebabCase = function kebabCase(str) {
    var camel = camelCase(str);
    return camel.replace(/[A-Z]/g, function (s) {
      return "-" + s.charAt(0).toString().toLowerCase();
    });
  };

  var setDataSetting = function setDataSetting(el, optionKey, defaultValue) {
    var value = el.getAttribute("data-".concat(kebabCase(optionKey)));

    if (isNum(value)) {
      value = parseInt(value);
    }

    if (value == 'true' || value == 'false') {
      value = toBoolean(value);
    }

    return value || defaultValue;
  };

  var searchObj = function searchObj(obj, currentKey, direction) {
    return Object.values(obj)[Object.keys(obj).indexOf(currentKey) + direction];
  };

  var currentObj = function currentObj(obj, currentKey) {
    return searchObj(obj, currentKey, 0);
  };

  var nextObj = function nextObj(obj, currentKey) {
    return searchObj(obj, currentKey, 1);
  };

  var prevObj = function prevObj(obj, currentKey) {
    return searchObj(obj, currentKey, -1);
  }; // 設定されている最後の'transition'を取得


  var getTransitionPropValues = function getTransitionPropValues(str) {
    return str.replace(/[A-Z]/gi, "").split(", ").map(parseFloat);
  };

  var getLastTransitionProp = function getLastTransitionProp(el) {
    var style = window.getComputedStyle(el);
    var props = style.transitionProperty.split(", ");
    var delays = getTransitionPropValues(style.transitionDelay);
    var durations = getTransitionPropValues(style.transitionDuration);
    var totals = durations.map(function (v, i) {
      return v + delays[i];
    });
    var maxIndex = totals.reduce(function (res, cur, i) {
      if (res.val > cur) {
        res.val = cur;
        res.i = i;
      }

      return res;
    }, {
      val: -Infinity,
      i: 0
    }).i;
    return props[maxIndex];
  }; // 新しいイベントを作成


  var createNewEvent = function createNewEvent(eventName) {
    var event;

    if ($.isFunction(Event)) {
      event = new Event(eventName);
    } else {
      event = document.createEvent('Event');
      event.initEvent(eventName, true, true);
    }

    return event;
  }; // 実行可能か判定


  var isExecutablePositionTypes = function isExecutablePositionTypes() {
    var activeIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    activeIndex = activeIndex || SlideMenu.activeIndex;
    return $.inArray(SlideMenu.els[SlideMenu.activeIndex].slidemenuLayout, SlideMenu.layoutTypes);
  };

  var applyNoscroll = function applyNoscroll() {
    SlideMenu.scrollTop = window.pageYOffset || options.windowScrollTarget.scrollTop;
    $.addClass($html, options.slidemenuNoscrollClass);
    $.setStyle($body, 'margin-top', -SlideMenu.scrollTop + SlideMenu.bodyMarginTop + 'px');
  };

  var cancelNoscroll = function cancelNoscroll() {
    $.setStyle($body, 'margin-top', "".concat(SlideMenu.bodyMarginTop, "px"));
    $.removeClass($html, options.slidemenuNoscrollClass);
    scrollTo(0, SlideMenu.scrollTop);
  };

  var openAnimLoop, closeAnimLoop;

  var openOverlayFade = function openOverlayFade() {
    var progress = Number(Number($.getStyle(SlideMenu.els[SlideMenu.activeIndex].$elWrapper, 'opacity')).toFixed(2));

    if (progress >= 0 && progress <= 1) {
      openAnimLoop = $.requestAnimationFrame(openOverlayFade, options.animationFrameTime);

      if (progress === 0) {
        $.cancelAnimationFrame(closeAnimLoop);
        $.setStyle(SlideMenu.$elOverlay, 'visibility', 'visible');
      }

      if (progress === 1) {
        $.cancelAnimationFrame(openAnimLoop);
      }
    }

    $.setStyle(SlideMenu.$elOverlay, 'opacity', progress);
  };

  var closeOverlayFade = function closeOverlayFade() {
    var progress = Number(Number($.getStyle(SlideMenu.els[SlideMenu.activeIndex].$elWrapper, 'opacity')).toFixed(2));

    if (progress >= 0 && progress <= 1) {
      closeAnimLoop = $.requestAnimationFrame(closeOverlayFade, options.animationFrameTime);

      if (progress === 1) {
        $.cancelAnimationFrame(openAnimLoop);
      }

      if (progress === 0) {
        $.cancelAnimationFrame(closeAnimLoop);
        $.setStyle(SlideMenu.$elOverlay, 'visibility', 'hidden');
      }
    }

    $.setStyle(SlideMenu.$elOverlay, 'opacity', progress);
  };

  var openOverlaySlide = function openOverlaySlide() {
    var target = SlideMenu.els[SlideMenu.activeIndex].$elWrapper;
    var value = $.offset(target).left;

    if (value > 0) {
      value = $body.clientWidth - value - target.offsetWidth;
    }

    var progress = Number(Number((target.offsetWidth + value) / target.offsetWidth).toFixed(2));

    if (progress >= 0 && progress <= 1) {
      openAnimLoop = $.requestAnimationFrame(openOverlaySlide, options.animationFrameTime);

      if (progress === 0) {
        $.cancelAnimationFrame(closeAnimLoop);
        $.setStyle(SlideMenu.$elOverlay, 'visibility', 'visible');
      }

      if (progress === 1) {
        $.cancelAnimationFrame(openAnimLoop);
      }
    }

    $.setStyle(SlideMenu.$elOverlay, 'opacity', progress);
  };

  var closeOverlaySlide = function closeOverlaySlide() {
    var target = SlideMenu.els[SlideMenu.activeIndex].$elWrapper;
    var value = $.offset(target).left;

    if (value > 0) {
      value = $body.clientWidth - value - target.offsetWidth;
    }

    var progress = Number(Number((target.offsetWidth + value) / target.offsetWidth).toFixed(2));

    if (progress >= 0 && progress <= 1) {
      closeAnimLoop = $.requestAnimationFrame(closeOverlaySlide, options.animationFrameTime);

      if (progress === 1) {
        $.cancelAnimationFrame(openAnimLoop);
      }

      if (progress === 0) {
        $.cancelAnimationFrame(closeAnimLoop);
        $.setStyle(SlideMenu.$elOverlay, 'visibility', 'hidden');
      }
    }

    $.setStyle(SlideMenu.$elOverlay, 'opacity', progress);
  }; // 新しいエンドイベント


  var transitionNoneEndEvent = createNewEvent('transitionnoneend'); // 開くを実行

  var open = function open(openIndex) {
    openIndex = openIndex || SlideMenu.activeIndex;
    SlideMenu.open = true;
    $.addClass($html, options.slidemenuOpenClass); // アクティブな要素以外を閉じる

    $.each(SlideMenu.els, function (slidemenuIndex, els) {
      if (openIndex !== slidemenuIndex) {
        $.removeClass(SlideMenu.els[slidemenuIndex].$elContainer, options.slidemenuOpenClass);
      }
    }); // アクティブな要素を開く

    $.addClass(SlideMenu.els[openIndex].$elContainer, options.slidemenuOpenClass);
    $.addClass(SlideMenu.els[openIndex].$elContainer, options.slidemenuOpenProgressClass); // 独自のエンドイベント

    SlideMenu.els[openIndex].$elWrapper.dispatchEvent(transitionNoneEndEvent); // スクロールポジションを固定

    applyNoscroll(); // オーバーレイの表示

    if (!options.useCss) {
      if (isExecutablePositionTypes()) {
        if (SlideMenu.els[openIndex].slidemenuLayout === 'center') {
          openOverlayFade();
        } else {
          openOverlaySlide();
        }
      } else {
        SlideMenu.eventFlag = false;
      }
    } // 専用のハッシュを追加


    history.pushState({}, document.title, '#smopen');
  }; // 閉じるを実行


  var close = function close() {
    SlideMenu.open = false;
    $.removeClass($html, options.slidemenuOpenClass); // 全要素を閉じる

    $.each(SlideMenu.els, function (slidemenuIndex, els) {
      $.removeClass(SlideMenu.els[slidemenuIndex].$elContainer, options.slidemenuOpenClass);
    }); // スクロールポジションの固定を解除

    if (SlideMenu.activeIndex === null) {
      cancelNoscroll();
      SlideMenu.eventFlag = false;
    } else {
      // 独自のエンドイベント
      SlideMenu.els[SlideMenu.activeIndex].$elWrapper.dispatchEvent(transitionNoneEndEvent); // オーバーレイの非表示

      if (!options.useCss) {
        if (isExecutablePositionTypes()) {
          if (SlideMenu.els[SlideMenu.activeIndex].slidemenuLayout === 'center') {
            closeOverlayFade();
          } else {
            closeOverlaySlide();
          }
        } else {
          SlideMenu.eventFlag = false;

          if (!SlideMenu.open) {
            cancelNoscroll();
          }
        }
      } // 専用のハッシュを除いて保存


      var uri = window.location.toString();

      if (uri.indexOf("#") > 0) {
        var replaceUri = uri.substring(0, uri.indexOf("#smopen"));
        window.history.replaceState({}, document.title, replaceUri);
      }
    }
  }; // キーボード操作のイベント


  var eventKeyboadTapHandler = function eventKeyboadTapHandler(e) {
    if (SlideMenu.eventFlag) {
      return false;
    }

    if (SlideMenu.open) {
      if (e.keyCode === 27) {
        close();
      }
    }
  }; // ボタンタップのイベント


  var eventButtonTapHandler = function eventButtonTapHandler(activeIndex, e) {
    if (SlideMenu.eventFlag) {
      return false;
    }

    SlideMenu.eventFlag = true; // イベントの停止

    $.eventPreventDefault(e); // アクティブなスライドメニューインデックスの保存

    SlideMenu.activeIndex = activeIndex; // 不明なアクションの場合

    if (!isExecutablePositionTypes()) {
      SlideMenu.eventFlag = false;
      return false;
    }

    $.toggleClass($html, options.slidemenuOpenClass);

    if ($.hasClass($html, options.slidemenuOpenClass)) {
      $.removeClass(SlideMenu.els[activeIndex].$elContainer, 'slidemenu-preventer');
      open(activeIndex);
    } else {
      close(activeIndex);
    }
  }; // オーバーレイタップのイベント


  var eventOverlayTapHandler = function eventOverlayTapHandler(e) {
    if (SlideMenu.eventFlag) {
      return false;
    } // 全要素を閉じる


    if (SlideMenu.open) {
      close();
    }
  };

  var eventBrowserbackHandler = function eventBrowserbackHandler(e) {
    if (SlideMenu.eventFlag) {
      return false;
    } // 全要素を閉じる


    if (SlideMenu.open) {
      close();
    }
  }; // 開閉後に実行するイベント 'transitionend'


  var eventCompleteHandler = function eventCompleteHandler(slidemenuIndex, e) {
    var target = e.target || e.srcElement;
    var lastTransition = getLastTransitionProp(target);

    if (e.propertyName == lastTransition && slidemenuIndex === SlideMenu.activeIndex) {
      SlideMenu.eventFlag = false;

      if (!SlideMenu.open) {
        cancelNoscroll(); // オープン中のクラス名を削除

        $.each(SlideMenu.els, function (slidemenuIndex, els) {
          $.removeClass(SlideMenu.els[slidemenuIndex].$elContainer, options.slidemenuOpenProgressClass);
        });
      }
    }
  };

  var init = function init() {
    if (SlideMenu.els.length) {
      return;
    }

    var $slidemenuContainers = document.getElementsByClassName(options.slidemenuContainerClass);

    if (!$slidemenuContainers.length) {
      return;
    }

    var $elContainer, $elTrigger, $elWrapper, $elContent;
    $.each($slidemenuContainers, function (slidemenuIndex, $slidemenuContainer) {
      $elContainer = $slidemenuContainer;
      $elTrigger = $slidemenuContainer.querySelector(".".concat(options.slidemenuTriggerClass));
      $elWrapper = $slidemenuContainer.querySelector(".".concat(options.slidemenuWrapperClass));
      $elContent = $slidemenuContainer.querySelector(".".concat(options.slidemenuContentClass)); // 条件を満たせばリストに追加

      if ($elTrigger && $elWrapper) {
        var data = {
          $elContainer: $elContainer,
          $elTrigger: $elTrigger,
          $elWrapper: $elWrapper,
          $elContent: $elContent,
          slidemenuIndex: slidemenuIndex,
          useTransition: true,
          eventHandlers: [],
          // 削除できるようにあらかじめイベントは登録する
          slidemenuLayout: options.slidemenuLayout
        }; // レスポンシブ用のメディアクエリリストを構築

        var buildMediaQueries = [];

        if ($.isObject(options.responsive)) {
          var key, current, next, prev, max, min;

          for (key in options.responsive) {
            if (options.responsive.hasOwnProperty(key)) {
              current = options.responsive[key];
              next = nextObj(options.responsive, key);
              prev = prevObj(options.responsive, key); // 次がない場合（最後の要素）

              if (typeof next === 'undefined') {
                // 前後がない場合（一つだけ指定されている場合）
                if (typeof prev === 'undefined') {
                  data.slidemenuLayout = current.slidemenuLayout;
                } // 前がある場合（中間の要素）
                else {
                    max = current.breakpoint - 0.02;
                    buildMediaQueries.push({
                      mq: "screen and (min-width: ".concat(prev.breakpoint, "px) and (max-width: ").concat(max, "px)"),
                      layout: setDataSetting(data.$elContainer, "slidemenu-".concat(key, "-layout"), setDataSetting(data.$elContainer, "slidemenu-layout", current.slidemenuLayout)),
                      media: key
                    });
                    buildMediaQueries.push({
                      mq: "screen and (min-width: ".concat(current.breakpoint, "px)"),
                      layout: setDataSetting(data.$elContainer, "slidemenu-".concat(key, "-layout"), setDataSetting(data.$elContainer, "slidemenu-layout", current.slidemenuLayout)),
                      media: key
                    });
                  }
              } // 次がある場合
              else {
                  // 前がない場合（最初の要素）
                  if (typeof prev === 'undefined') {
                    max = current.breakpoint - 0.02;
                    buildMediaQueries.push({
                      mq: "screen and (max-width: ".concat(max, "px)"),
                      layout: setDataSetting(data.$elContainer, "slidemenu-".concat(key, "-layout"), setDataSetting(data.$elContainer, "slidemenu-layout", current.slidemenuLayout)),
                      media: key
                    });
                  } // 前がある場合（中間の要素）
                  else {
                      max = current.breakpoint - 0.02;
                      buildMediaQueries.push({
                        mq: "screen and (min-width: ".concat(prev.breakpoint, "px) and (max-width: ").concat(max, "px)"),
                        layout: setDataSetting(data.$elContainer, "slidemenu-".concat(key, "-layout"), setDataSetting(data.$elContainer, "slidemenu-layout", current.slidemenuLayout)),
                        media: key
                      });
                    }
                }
            }
          }
        } // レスポンシブようのクエリリストがあれば登録


        if (buildMediaQueries.length) {
          data.slidemenuLayout = buildMediaQueries;
        } // レスポンシブに対応する場合


        if ($.isArray(data.slidemenuLayout)) {
          var mq,
              mqCallback = function mqCallback(obj, e) {
            if (e.matches) {
              data.slidemenuLayout = obj.layout;
              data.$elContainer.dataset.slidemenuActiveLayout = data.slidemenuLayout; // データ属性を動的に変換

              if (SlideMenu.open && !isExecutablePositionTypes()) {
                close();
              }
            }
          };

          $.each(buildMediaQueries, function (i, obj) {
            mq = window.matchMedia(obj.mq);
            mq.addListener(mqCallback.bind(null, obj));
            mqCallback(obj, mq);
          });
        } // レスポンシブに対応しない場合
        else {
            data.$elContainer.dataset.slidemenuActiveLayout = data.slidemenuLayout;
          } // トランジションを利用できるか


        data.useTransition = getLastTransitionProp(data.$elWrapper) != 'none'; // イベント

        data.eventHandlers.push({
          'target': data.$elTrigger,
          'event': 'click',
          'function': eventButtonTapHandler.bind(null, slidemenuIndex)
        });
        data.eventHandlers.push({
          'target': document,
          'event': 'keydown',
          'function': eventKeyboadTapHandler
        });
        data.eventHandlers.push({
          'target': window,
          'event': 'popstate',
          'function': eventBrowserbackHandler
        });
        data.eventHandlers.push({
          'target': data.$elWrapper,
          'event': 'transitionend',
          'function': eventCompleteHandler.bind(null, slidemenuIndex)
        });
        $.each(data.eventHandlers, function (i, obj) {
          $.on(obj.target, obj.event, obj["function"]);
        }); // データの登録

        SlideMenu.els[slidemenuIndex] = data;
      }
    }); // アニメーションをcssのみで行う判別

    if (options.useCss) {
      $.addClass($html, 'slidemenu-use-css');
    } // オーバーレイ要素のセット


    SlideMenu.$elOverlay = $body.querySelector(".".concat(options.slidemenuOverlayClass));

    if (!SlideMenu.$elOverlay) {
      SlideMenu.$elOverlay = document.createElement('div');
      SlideMenu.$elOverlay.className = options.slidemenuOverlayClass;
      $body.appendChild(SlideMenu.$elOverlay); // オーバーレイ要素のタップイベント

      $.on(SlideMenu.$elOverlay, 'click', eventOverlayTapHandler);
    }
  };

  var destroy = function destroy() {
    // 閉じるを一度実行
    close();

    if (SlideMenu.els.length) {
      $.each(SlideMenu.els, function (i, els) {
        $.each(els.eventHandlers, function (i, obj) {
          $.off(obj.target, obj.event, obj["function"]);
        });
      });
    } // アニメーションをcssのみで行う判別


    if (options.useCss) {
      $.removeClass($html, 'slidemenu-use-css');
    } // オーバーレイ要素のリセット


    if (SlideMenu.$elOverlay) {
      // オーバーレイ要素のタップイベント
      $.off(SlideMenu.$elOverlay, 'click', eventOverlayTapHandler);
      SlideMenu.$elOverlay.parentNode.removeChild(SlideMenu.$elOverlay);
      SlideMenu.$elOverlay = null;
    } // グローバル値をリセット


    initialGlobalOptions();
  };

  setTimeout(function () {
    if (options.init) {
      init();
    }
  });
  $.extend(SlideMenu, {
    options: options,
    init: init,
    destroy: destroy
  });
  return SlideMenu;
});

},{}],7:[function(require,module,exports){
"use strict";

var _domManipulation = _interopRequireDefault(require("./../../framework/dom-manipulation/dom-manipulation"));

require("./../../framework/instantpage/instantpage");

require("./../../framework/page-scroll/page-scroll");

require("./../../framework/slide-menu/slide-menu");

require("./../../framework/ripple/ripple");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

window.$ = DOMM.fn; // Framewok

// import './../../framework/photoswipe/photoswipe';
(function ($) {
  var pageTop = document.getElementById('pageTop');
  $.addClass(pageTop, 'init-pagetop');
  $.on(window, 'scroll', function (e) {
    if ($.windowScrollTarget.scrollTop > 200) {
      $.addClass(pageTop, 'show-pagetop');
    } else {
      $.removeClass(pageTop, 'show-pagetop');
    }
  });
})(DOMM.fn);

},{"./../../framework/dom-manipulation/dom-manipulation":1,"./../../framework/instantpage/instantpage":3,"./../../framework/page-scroll/page-scroll":4,"./../../framework/ripple/ripple":5,"./../../framework/slide-menu/slide-menu":6}]},{},[7])(7)
});
